import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import BlogFilter from '../components/BlogFilter';

const Blog = () => {
   return (
    <Layout>
      <SEO title="Blog" description="Edukativni tekstovi i savjeti posvećeni psihologiji, mentalnom zdravlju, savjetovanju i psihoterapiji." />
      <main className="page blog-view">
      <div className="Search">
      <h1 style={{ textAlign: `center` }}><strong className="pageNaslov">Apsiha </strong> Blog</h1>
      <BlogFilter />
      </div>
      </main>
    </Layout>
  )
}

export default Blog