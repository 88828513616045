import React, { useState } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const BlogFilter = () => {
  const [activeCategory, setActiveCategory] = useState("All"); // Default category set to "All"
  const [searchQuery, setSearchQuery] = useState(""); // State to store search input

  // Static GraphQL query to get all Blog posts with frontmatter
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
      sort: {fields: frontmatter___date, order: DESC}
      filter: {frontmatter: {type: {eq: "blog_input"}}}
    ) {
        nodes {
          id
          frontmatter {
            slug
            title
            date
            description
            author
            category
            id
            thumb {
              childImageSharp {
                gatsbyImageData(
                  placeholder: DOMINANT_COLOR
                  transformOptions: {fit: CONTAIN}
                  width: 450
                )
              }
            }
          }
          excerpt
        }
      }
    }
  `);

  // Filter posts based on the active category or search query
  const filteredPosts = data.allMarkdownRemark.nodes.filter((post) => {
    // If search query exists, filter based on title, tags, or excerpt
    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      const title = post.frontmatter.title.toLowerCase();
      const excerpt = post.excerpt.toLowerCase();
      const description = post.frontmatter.description.toLowerCase();
      const tags = post.frontmatter.tags?.join(" ").toLowerCase() || ""; // Join tags into a string

      return (
        title.includes(query) ||
        excerpt.includes(query) ||
        description.includes(query) ||
        tags.includes(query)
      );
    }
    // If no search query and "All" is selected, show all posts
    if (activeCategory === "All") {
      return true;
    }
    // Otherwise, filter by selected category
    return post.frontmatter.category === activeCategory;
  });

  const handleButtonClick = (category) => {
    setActiveCategory(category);
    setSearchQuery(""); // Clear search when changing category
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <div>
      <div id="myBtnContainer">
      <button
          className={`btn ${activeCategory === 'All' ? "active" : ""}`}
          onClick={() => handleButtonClick('All')}
        >
          Prikaži sve
        </button>
        <button
          className={`btn ${activeCategory === 'psihologija' ? "active" : ""}`}
          onClick={() => handleButtonClick('psihologija')}
        >
          Psihološke teme
        </button>
        <button
          className={`btn ${activeCategory === 'emocije' ? "active" : ""}`}
          onClick={() => handleButtonClick('emocije')}
        >
          Emocionalne teškoće
        </button>
        <button
          className={`btn ${activeCategory === 'savjeti' ? "active" : ""}`}
          onClick={() => handleButtonClick('savjeti')}
        >
          Praktični savjeti
        </button>
        <button
          className={`btn ${activeCategory === 'student' ? "active" : ""}`}
          onClick={() => handleButtonClick('student')}
        >
          Studentski život
        </button>
      </div>
      <br/>
      <div className="search-container">
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Pretraži Blog postove..."
        />
      </div>
      <div className="blog-posts">
      {filteredPosts.length > 0 ? (
      <div className="blogs-list" id="blogs">
            {filteredPosts.map((post) => {
            return (  
              <Link to={"../blog/" + post.frontmatter.slug} key={post.frontmatter.id}>
                <div className="BlogThumb boxedIn">
                  <GatsbyImage
                  image={getImage(post.frontmatter.thumb.childImageSharp.gatsbyImageData)}
                  alt={post.frontmatter.title}
                  />
                  <div className="BlogDescript">
                  <h4><strong>{post.frontmatter.title}</strong></h4>
                  <p>{post.frontmatter.description}</p>
                  <p className="kurziv">autor: {post.frontmatter.author}, {post.frontmatter.date}</p>
                  </div>
                </div>
              </Link>
              )
            })}
          </div>
        ) : (
          <p>Nema dostupnih postova za ovu kategoriju ili upit za pretraživanje.</p>
        )}
      </div>
    </div>
  );
};

export default BlogFilter;