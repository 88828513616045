import React from "react"
import Navbar from "./Navbar"
import Footer from "./Footer"
import ScrollButton from './ScrollButton'
import { Helmet } from "react-helmet";
//import "normalize.css"
import "../assets/css/main.css"

const Layout = ({ children }) => {
  return (
    <>
      <Helmet>
      <link rel="icon" href="/favicon.ico" />
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      </Helmet>
      <Navbar />
      {children}
      <ScrollButton />
      <Footer />
    </>
  )
}

export default Layout
